import {Link} from "react-router-dom";


function Navigation() {
    return (
        <header className="App-header">
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
                <div className="container">
                    <Link className="navbar-brand" to="/">BaStones</Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link className="nav-link" to="/cartography">Cartographie</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/ranking">Ranking</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/file-tracking">Suivi des dossiers</Link>
                            </li>
                        </ul>
                    </div>
                    <span className="navbar-text">
                      Produit KSI
                    </span>
                </div>
            </nav>
        </header>
    )
}

export default Navigation;