const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function fetchFavorites(insee_code_filter= '', county_code_filter = '') {
    const param = insee_code_filter ? `?insee_code=${insee_code_filter}` :
            county_code_filter ? `?county_code=${county_code_filter}` :
            '';

    try {
        const response = await fetch(`${API_BASE_URL}/favorites${param}`);
        console.log(response);
        return response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export async function addFavorite(id){
    try {
        const response = await fetch(`${API_BASE_URL}/favorites`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: id }),
        });

        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
}

export async function updateFavorite(data){
    try {
        const response = await fetch(`${API_BASE_URL}/favorites/${data.id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
}

export async function removeFavorite(id) {
    const response = await fetch(`${API_BASE_URL}/favorites/${id}`, {
        method: 'DELETE',
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
}
