const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function fetchRanking(limit= 50, force_plui = false) {
    try {
        const response = await fetch(`${API_BASE_URL}/municipalities/ranking?limit=` + limit + `&force_plui=` + force_plui);
        console.log(response);
        return response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export async function fetchRankingByPage(page= 1, force_plui = false) {
    try {
        const response = await fetch(`${API_BASE_URL}/municipalities/ranking?page=` + page + `&force_plui=` + force_plui);
        console.log(response);
        return response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}