let abortControllers = {};

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function fetchDepartementsGeoJSON() {
    try {
        const response = await fetch(`${API_BASE_URL}/counties`);
        const geoJsonData =  await response.json();

        geoJsonData.features.forEach(feature => {
            feature.properties.type = "departement";
        });
        return geoJsonData;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export async function fetchDepartementInfo(id) {
    try {
        const response = await fetch(`${API_BASE_URL}/counties/${id}`);
        const geoJsonData =  await response.json();

        geoJsonData.features.forEach(feature => {
            feature.properties.type = "departement";
        });
        return geoJsonData;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export async function fetchCommunesGeoJSON(ne_lat, ne_lng, sw_lat, sw_lng) {
    if (abortControllers.communes) {
        abortControllers.communes.abort();
    }
    abortControllers.communes = new AbortController();

    try {
        const response = await fetch(
            `${API_BASE_URL}/municipalities?bbox=`+ne_lat+','+ne_lng+','+sw_lat+','+sw_lng,{
             signal: abortControllers.communes.signal
            });
        const geoJsonData = await response.json();

        geoJsonData.features.forEach(feature => {
            feature.properties.type = "commune";
        });
        return geoJsonData;
    }
    catch (error) {
         if (error.name === 'AbortError') {
            console.log('Fetch aborted');
        } else {
            console.error('Error fetching data:', error);
            throw error;
        }
    }
}

export async function fetchCommuneInfo(id) {
    try {
        const response = await fetch(`${API_BASE_URL}/municipalities/${id}`);
        const geoJsonData =  await response.json();

        geoJsonData.features.forEach(feature => {
            feature.properties.type = "commune";
        });
        return geoJsonData;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export async function fetchSectionsGeoJSON(ne_lat, ne_lng, sw_lat, sw_lng) {
    try {
        const response = await fetch(`${API_BASE_URL}/sections?bbox=`+ne_lat+','+ne_lng+','+sw_lat+','+sw_lng);
        const geoJsonData = await response.json();

        geoJsonData.features.forEach(feature => {
            feature.properties = {...feature.properties};
            feature.properties.type = "section";
        });
        return geoJsonData;
    }
    catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export async function fetchParcellesGeoJSON(ne_lat, ne_lng, sw_lat, sw_lng) {
    try {
        const response = await fetch(`${API_BASE_URL}/parcels?bbox=`+ne_lat+','+ne_lng+','+sw_lat+','+sw_lng);
        const geoJsonData = await response.json();

        geoJsonData.features.forEach(feature => {
            feature.properties = {...feature.properties};
            feature.properties.type = "parcelle";
        });
        return geoJsonData;
    }
    catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export async function fetchEnedisHTA(ne_lat, ne_lng, sw_lat, sw_lng){
    try {
        // Premier appel à l'API pour reseau-souterrain-hta
        const response1 = await fetch(
            "https://data.enedis.fr/api/explore/v2.1/catalog/datasets/reseau-souterrain-hta/records?where=in_bbox(geo_shape%20%2C" +
            sw_lat + "%2C" +
            sw_lng + "%2C" +
            ne_lat + "%2C" +
            ne_lng +
            ")&limit=100"
        );
        let res1 = await response1.json();

        // Deuxième appel à l'API pour reseau-hta
        const response2 = await fetch(
            "https://data.enedis.fr/api/explore/v2.1/catalog/datasets/reseau-hta/records?where=in_bbox(geo_shape%20%2C" +
            sw_lat + "%2C" +
            sw_lng + "%2C" +
            ne_lat + "%2C" +
            ne_lng +
            ")&limit=100"
        );
        let res2 = await response2.json();

        // Fonction pour transformer les résultats en Features GeoJSON
        const transformToFeature = (result, type) => ({
            type: "Feature",
            geometry: result.geo_shape.geometry,
            properties: {
                code_commune: result.code_commune,
                nom_commune: result.nom_commune,
                code_epci: result.code_epci,
                nom_epci: result.nom_epci,
                code_departement: result.code_departement,
                nom_departement: result.nom_departement,
                code_region: result.code_region,
                nom_region: result.nom_region,
                geo_point_2d: result.geo_point_2d,
                type: type
            }
        });

        // Combinaison des résultats des deux appels API
        let features = [
            ...res1.results.map(result => transformToFeature(result, 'reseau-souterrain-hta')),
            ...res2.results.map(result => transformToFeature(result, 'reseau-hta'))
        ];

        // Construction du GeoJSON final
        let geojson = {
            type: "FeatureCollection",
            features: features
        };

        console.log(geojson);
        return geojson;
    }
    catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}