import RankingDataTable from "../components/DataTables/RankingDataTable";
import {useEffect, useState} from "react";
import {fetchRanking, fetchRankingByPage} from "../services/ApiRankingService";
import {useLocation, useNavigate} from "react-router";
import {Button} from "react-bootstrap";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

function RankingView(){
    const query = useQuery();
    const navigate = useNavigate();
    const initialLimit = parseInt(query.get('limit')) || 50;
    const initialPage = parseInt(query.get('page')) || 1;

    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [forcePlui, setForcePlui] = useState(false);
    const [page, setPage] = useState(initialPage);

    useEffect(() => {
        fetchRankingByPage(page, forcePlui).then(response => {
            setTableData(response);
            setLoading(false);
        })
    }, [page, forcePlui])

    useEffect(() => {
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set('page', page.toString());
        navigate(`${window.location.pathname}?${currentParams}`);
    }, [page, navigate]);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    return (
        <div className={"ranking"}>
        <RankingDataTable
            data={tableData} loading={loading} forcePlui={forcePlui} setForcePlui={setForcePlui}
            currentPage={page}
            handlePageChange={handlePageChange}
            totalPages={100}
        />
        </div>
    );
}

export default RankingView