import React, {useEffect} from 'react';
import './DataTable.css'
import { CSVLink, CSVDownload } from "react-csv";
import Loader from "../Loader";
import {Button} from "react-bootstrap";
import {Download} from "react-bootstrap-icons";

const FavoriteDataTable = ({ data, title, loading, onDelete, onEdit }) => {


  return (
    <div>
      <h2>{title}</h2>
      <Button variant="success" size="sm" className="mx-4 my-1">
        <CSVLink data={data} className="text-white text-decoration-none d-flex align-items-center" filename={`export_suivis_${Date.now()}.csv`}>
          <Download className="me-2" /> Télécharger
        </CSVLink>
      </Button>
      {loading ? <Loader /> :
          <table className="data-table">
              <thead>
                  <tr>
                      <th>ID</th>
                      <th>Code INSEE</th>
                      <th>Département</th>
                      <th>Propriétaire</th>
                      <th>Prénom</th>
                      <th>Nom</th>
                      <th>Adresse</th>
                      <th>Téléphone</th>
                      <th>Email</th>
                      <th>Contacté</th>
                      <th>Répondu</th>
                      <th>Masqué</th>
                      <th>Notes</th>
                      <th>Actions</th>
                  </tr>
              </thead>
              <tbody>
              {data.map(item => (
                  <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.insee}</td>
                      <td>{item.county}</td>
                      <td>{item.ownership ? "Yes" : "No"}</td>
                      <td>{item.first_name}</td>
                      <td>{item.last_name}</td>
                      <td>{item.address}</td>
                      <td>{item.phone_number}</td>
                      <td>{item.email}</td>
                      <td>{item.contacted ? "Yes" : "No"}</td>
                      <td>{item.answered ? "Yes" : "No"}</td>
                      <td>{item.hidden ? "Yes" : "No"}</td>
                      <td>{item.notes}</td>
                      <td>
                          <Button variant="primary"
                                  size="sm"
                                  className="mx-1 my-1"
                                  onClick={() => onEdit(item)}
                          >
                              Éditer
                          </Button>
                          <Button variant="danger"
                                  size="sm"
                                  className="mx-1 my-1"
                                  onClick={() => onDelete(item.id)}
                          >
                              Supprimer
                          </Button>
                      </td>
                  </tr>
              ))}
              </tbody>
          </table>
      }
    </div>
  );
};

export default FavoriteDataTable;
