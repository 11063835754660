import React from 'react';
import '../App.css';

const Loader = ({ light = false }) => {
    return (
        <div className={`loader-container ${light ? 'light' : ''}`}>
            <div className={`loader ${light ? 'light-loader' : ''}`}></div>
        </div>
    );
};

export default Loader;