import {useEffect, useState} from "react";
import {fetchFavorites, removeFavorite} from "../services/ApiFollowService";
import FavoritesDataTable from "../components/DataTables/FavoritesDataTable";
import DetailModal from "../components/DetailModal/DetailModal";

function TrakingView(){
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    useEffect(() => {
        fetchFavorites().then(response => {
            setTableData(response);
            setLoading(false);
        })
    }, [])

    const handleDelete = (id) => {
        setLoading(true);
        removeFavorite(id).then(() => {
            setTableData(prevData => prevData.filter(item => item.id !== id));
            setLoading(false);
        });
    }

    const handleOpenModal = (data) => {
        setSelectedData(data);
        setIsModalOpen(true);
    }

    const handleCloseModal = () => {
        setLoading(true);
        setIsModalOpen(false);
        setSelectedData(null);
        fetchFavorites().then(response => {
            setTableData(response);
            setLoading(false);
        })
    }


    return <>
            <FavoritesDataTable title={"Suivi des dossiers"} data={tableData} loading={loading} onDelete={handleDelete} onEdit={handleOpenModal}  />
            <DetailModal isOpen={isModalOpen} onClose={handleCloseModal} data={selectedData} />
        </>
}

export default TrakingView