import React, {useState} from 'react';
import './DataTable.css'
import {Link} from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import {CheckSquareFill, DashSquare, Download} from "react-bootstrap-icons";
import Loader from "../Loader";
import {Button, Pagination} from "react-bootstrap";

const extractCoordinates = (coordinateString) => {
  const hexString = coordinateString.slice(16); // Ignore le préambule
  const longitude = parseFloat(`0x${hexString.slice(0, 16)}`);
  const latitude = parseFloat(`0x${hexString.slice(16, 32)}`);
  return { longitude, latitude };
};

const RankingDataTable = ({ data, title, loading, setForcePlui, currentPage, handlePageChange, totalPages }) => {
    const generateMapUrl = (longitude, latitude) => {
        return `/cartography?center_long=${longitude}&center_lat=${latitude}`;
    };

    if (title) return <div>
        <h2>{title}</h2>
    </div>

    return (
    <div>
        <h2>Classement des communes</h2>
        <div className="table-buttons mx-4 my-1">
            <Button variant="success" size="sm">
            <CSVLink data={data} className="text-white text-decoration-none d-flex align-items-center" filename={`export_ranking_${Date.now()}.csv`}>
              <Download className="me-2" /> Télécharger
            </CSVLink>
            </Button>
            <Pagination>
                <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                {Array.from({ length: Math.min(5, totalPages) }, (_, idx) => {
                  const pageNumber = idx + Math.max(1, currentPage - 2);
                  if (pageNumber > totalPages) return null;
                  return (
                    <Pagination.Item
                      key={pageNumber}
                      active={pageNumber === currentPage}
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </Pagination.Item>
                  );
                })}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
            </Pagination>
        </div>
      {loading ? <Loader /> :
      <table className="data-table">
        <thead>
        <tr>
          <th>Nom</th>
          <th>Score</th>
          <th>Ensoleillement</th>
          <th>Prix Minimum (€/m²)</th>
          <th>Prix Médian (€/m²)</th>
          <th>Prix Maximum (€/m²)</th>
          <th><input type={"checkbox"} onChange={(data) => setForcePlui(data.target.checked)}/> PLU dispo ?</th>
          <th>Nb Parcelles</th>
          {/*<th>Status</th>*/}
          <th>Carte</th>
        </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            // const mapUrl = `/cartography?center_long=${item.coordinates.longitude}&center_lat=${item.coordinates.latitude}&zoom=13`;
            // const mapUrl = `/cartography?search=${item.name}%20${item.insee_code}`
            const mapUrl = `/cartography?search=${item.coordinates.latitude - 0.009},${item.coordinates.longitude}`
            return (
                <tr key={index}>
                  <td>{item.name} ({item.county_code})</td>
                  <td title={item.score}>{item.score.toFixed(0)}</td>
                  <td>{item.sunlight}</td>
                  <td title={item.minimum_price}>{item.minimum_price.toFixed(2)}</td>
                  <td title={item.median_price}>{item.median_price.toFixed(2)}</td>
                  <td title={item.maximum_price}>{item.maximum_price.toFixed(2)}</td>
                  <td>
                    {item.has_plui ? <CheckSquareFill /> : <DashSquare />}
                  </td>
                  <td>{item.count}</td>
                  {/*<td>Non visité</td>*/}
                  <td><Link to={mapUrl}>Voir sur la carte</Link></td>
                </tr>
            )
          })}
        </tbody>
      </table>
      }
    </div>
    );
};

export default RankingDataTable;
