import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import {XLg} from "react-bootstrap-icons";
import {updateFavorite} from "../../services/ApiFollowService";

const DetailModal = ({ isOpen, onClose, data }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
      updateFavorite(formData).then(r => {
      console.log('Data saved:', r)
      onClose();
    })
  };

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Détails pour la parcelle {formData.id}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formInsee">
            <Form.Label>INSEE</Form.Label>
            <Form.Control
              type="text"
              name="insee"
              value={formData.insee || ''}
              onChange={handleChange}
              disabled
            />
          </Form.Group>
          <Form.Group controlId="formCounty">
            <Form.Label>Département</Form.Label>
            <Form.Control
              type="text"
              name="county"
              value={formData.county || ''}
              onChange={handleChange}
              disabled
            />
          </Form.Group>
          <Form.Group controlId="formOwnership">
            <Form.Check
              type="checkbox"
              label="Propriétaire ?"
              name="ownership"
              checked={formData.ownership || false}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  ownership: e.target.checked,
                })
              }
            />
          </Form.Group>
          <Form.Group controlId="formFirstName">
            <Form.Label>Prénom</Form.Label>
            <Form.Control
              type="text"
              name="first_name"
              value={formData.first_name || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formLastName">
            <Form.Label>Nom</Form.Label>
            <Form.Control
              type="text"
              name="last_name"
              value={formData.last_name || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formAddress">
            <Form.Label>Adresse</Form.Label>
            <Form.Control
              type="text"
              name="address"
              value={formData.address || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formPhoneNumber">
            <Form.Label>Téléphone</Form.Label>
            <Form.Control
              type="text"
              name="phone_number"
              value={formData.phone_number || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formContacted">
            <Form.Check
              type="checkbox"
              label="Contacté"
              name="contacted"
              checked={formData.contacted || false}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  contacted: e.target.checked,
                })
              }
            />
          </Form.Group>
          <Form.Group controlId="formAnswered">
            <Form.Check
              type="checkbox"
              label="Répondu"
              name="answered"
              checked={formData.answered || false}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  answered: e.target.checked,
                })
              }
            />
          </Form.Group>
          <Form.Group controlId="formHidden">
            <Form.Check
              type="checkbox"
              label="Masqué"
              name="hidden"
              checked={formData.hidden || false}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  hidden: e.target.checked,
                })
              }
            />
          </Form.Group>
          <Form.Group controlId="formNotes">
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as="textarea"
              name="notes"
              value={formData.notes || ''}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          <XLg />
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Sauvegarder
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DetailModal;
