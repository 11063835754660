import React, { useEffect, useState } from 'react';
import {fetchCommuneInfo, fetchDepartementInfo} from "../../services/apiService";
import {addFavorite, fetchFavorites, removeFavorite} from "../../services/ApiFollowService";
import Loader from "../Loader";

function InformationSidebar({properties}){
    const [isFollowing, setIsFollowing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [metaProperties, setmetaProperties] = useState({
        county_code: undefined,
        county_data: {},
        municipality_insee_code: undefined,
        municipality_data: {}
    });

    useEffect(() => {
        console.log(properties)
        if (properties.type === "parcelle"){
            let insee_code = properties.id.substring(0, 5);
            let departement_code = properties.id.substring(0, 2);

            Promise.all([
                fetchDepartementInfo(departement_code),
                fetchCommuneInfo(insee_code),
                fetchFavorites(insee_code)
            ]).then(([departementData, communeData, favoriteList]) => {
                const countyData = departementData.features[0].properties;
                const communeDataProps = communeData.features[0].properties;

                console.log('Departement Data:', countyData);
                console.log('Commune Data:', communeDataProps);
                console.log('favorites :', favoriteList)

                setIsFollowing(favoriteList.some(favorite => favorite.id === properties.id));

                setmetaProperties({
                    county_code: insee_code,
                    county_data: countyData,
                    municipality_insee_code: departement_code,
                    municipality_data: communeDataProps
                });
            }).catch(error => {
                console.error('Error fetching data:', error);
            });
        }
    }, [properties])

    const handleFollowChange = async (event) => {
        const shouldFollow = event.target.checked;
        setLoading(true);
        setError(null);

        try {
            if (shouldFollow) {
                await addFavorite(properties.id);
            } else {
                await removeFavorite(properties.id);
            }
            setIsFollowing(shouldFollow);
            console.log('Success');
        } catch (error) {
            setError(error);
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    if (properties.type === "departement") {
        return <div className="information-sidebar information-departement">
            <div className="display-selector">
                <p>Département : <b>{properties.nom} ({properties.code})</b></p>
                <p>Soleil : <b>{properties.sunlight}</b> heures/an</p>
            </div>
        </div>
    }
    else if (properties.type === "commune") {
        return <div className="information-sidebar information-commune">
            <div className="display-selector">
                <p>Commune : <b>{properties.name} ({properties.county_code})</b></p>
                <p>Code INSEE : <b>{properties.insee_code}</b></p>
                <p>Prix Minimum : <b>{properties.minimum_price.toFixed(2)}</b> €/m²</p>
                <p>Prix Médian : <b>{properties.median_price.toFixed(2)}</b> €/m²</p>
                <p>Prix Maximum : <b>{properties.maximum_price.toFixed(2)}</b> €/m²</p>
            </div>
        </div>
    } else if (properties.type === "parcelle") {
        return <div className="information-sidebar information-parcelle">
            <div className="display-selector">
                <p>Parcelle <b>{properties.id}</b> {properties.valid ? "VALIDE (U/AU)" : "Non Valide"}</p>
                <p>Surface <b>{(properties.surface / 10000).toFixed(2)}</b> ha</p>
                <p key={metaProperties.municipality_insee_code}>à {metaProperties.municipality_data.name} ({metaProperties.municipality_data.county_code})</p>
                <ul>
                    <li key={metaProperties.municipality_insee_code}>
                        {metaProperties.municipality_data.has_plui === true ?
                            <>Parcelle <b>couverte</b> par les documents d’urbanisme (PLU/PLUi)</>
                            : <>Parcelle <b>NON COUVERTE</b> par les documents d'urbanisme</>}
                    </li>
                    <li key={metaProperties.county_code}>Ensoleillement : {metaProperties.county_data.sunlight} heures/an</li>
                    <li key={metaProperties.municipality_insee_code}>Prix médian du foncier : {metaProperties.municipality_data.median_price?.toFixed(2)} €/m^2</li>
                </ul>
            </div>
            <div className="display-selector">
                <h3>Actions</h3>
                <form>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={isFollowing}
                        onChange={handleFollowChange}
                        disabled={loading}
                    /> Suivre ce dossier
                </form>
                {loading ? <Loader light={true} /> : <></>}
                {error && <b className="error">Erreur: {error.message}</b>}
            </div>
        </div>
    }
}

export default InformationSidebar;