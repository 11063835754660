import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';


import MapView from './pages/Map/MapView';
import Ranking from './pages/Ranking';
import FileTracking from './pages/Traking';
import Navigation from "./components/Navigation";
import NoPage from './pages/404';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
            <Navigation />
            <main>
                <Routes>
                    <Route path="/" element={<MapView />}/>
                    <Route path="/cartography" element={<MapView />}/>
                    <Route path="/ranking" element={<Ranking />}/>
                    <Route path="/file-tracking" element={<FileTracking />}/>
                    <Route path="*" element={<NoPage/>}/>
                </Routes>
            </main>
            </BrowserRouter>
        </div>
    );
}

export default App;
