export const getDepartementColor = (filters, ensoleillementData, departement) => {
        // Trouver l'ensoleillement correspondant au département
        const ensoleillement = ensoleillementData.find(data => data.departement === departement);
        // Si l'ensoleillement est trouvé, retourner une couleur en fonction de la valeur
        if (ensoleillement && filters.departementVisibility) {
            const temps = ensoleillement.temps;
            // Utilisation d'une échelle de couleurs continue entre les valeurs minimales et maximales d'ensoleillement
            const minEnsoleillement = filters.minSun; // Valeur minimale d'ensoleillement
            const maxEnsoleillement = filters.maxSun; // Valeur maximale d'ensoleillement
            if (temps < minEnsoleillement || temps > maxEnsoleillement) {
                return 'transparent';
            }
            const minColor = [255, 0, 0]; // Couleur correspondant à la valeur minimale d'ensoleillement (vert foncé)
            const maxColor = [0, 128, 0]; // Couleur correspondant à la valeur maximale d'ensoleillement (rouge)

            // Interpoler les composantes de couleur entre les valeurs minimales et maximales ensoleillement
            const interpolatedColor = minColor.map((color, index) => {
                const min = minColor[index];
                const max = maxColor[index];
                // Calculer la composante de couleur interpolée
                return Math.round(min + (max - min) * ((temps - minEnsoleillement) / (maxEnsoleillement - minEnsoleillement)));
            });

            // Formatage de la couleur au format hexadécimal
            return `#${interpolatedColor.map(color => {
                const hex = color.toString(16);
                return hex.length === 1 ? '0' + hex : hex;
            }).join('')}`;
        } else {
            // Couleur par défaut si les données d'ensoleillement ne sont pas disponibles pour ce département
            return 'transparent';
        }
    };

export const getCommuneColor = (filters, properties, minPrix= 0, maxPrix= 1000) => {
    if (maxPrix > 45) maxPrix = 45;
    // Trouver le prix correspondant à la commune
    const prix = properties.median_price
    // Si le prix est trouvé, retourner une couleur en fonction de la valeur
    if (prix) {
        if (prix < minPrix || prix > maxPrix || !filters.communeVisiblity) {
            return 'rgba(255,255,255,0.3)'; // Couleur rouge par défaut si le prix est en dehors de la plage spécifiée
        }
        const minColor = [0, 128, 0]; // Couleur correspondant à la valeur minimale de prix (rouge)
        const maxColor = [255, 0, 0]; // Couleur correspondant à la valeur maximale de prix (vert)

        // Interpoler les composantes de couleur entre les valeurs minimales et maximales de prix
        const interpolatedColor = minColor.map((color, index) => {
            const min = minColor[index];
            const max = maxColor[index];
            // Calculer la composante de couleur interpolée
            return Math.round(min + (max - min) * ((prix - minPrix) / (maxPrix - minPrix)));
        });

        const alpha = 1 - ((prix - minPrix) / (maxPrix - minPrix));

        // Formatage de la couleur au format hexadécimal
        return `#${interpolatedColor.map(color => {
            const hex = color.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        }).join('')}`;
    } else {
        // Couleur par défaut si les données de prix ne sont pas disponibles pour cette commune
        return 'transparent';
    }
};

export const getParcelleColor = (filters, properties) => {
    const isValid = properties.valid !== false;

    return isValid ? 'blue' : 'transparent';
}
