import React, { useEffect, useState } from 'react';
import {MapContainer, TileLayer, GeoJSON, Tooltip, useMapEvents, useMap} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './MapComponent.css';
import {
    fetchCommunesGeoJSON,
    fetchDepartementsGeoJSON, fetchEnedisHTA,
    fetchParcellesGeoJSON,
    fetchSectionsGeoJSON
} from '../../services/apiService';
import {jsonizeTooltipContent} from '../../services/shared'
import {getCommuneColor, getDepartementColor, getParcelleColor} from "../../services/colorService";
import {getCommuneTooltip} from "../../services/colorService";
import Loader from "../Loader";
import {useSearchParams} from "react-router-dom";
import SearchControl from "./SerchControl";

function MapComponent({ filters, setters, isSatelliteChecked}) {
    const [searchParams] = useSearchParams();
    let centerLat = searchParams.get('center_long') ? parseInt(searchParams.get('center_long')) : 1.888334
    let centerLong = searchParams.get('center_lat') ? parseInt(searchParams.get('center_lat')) : 46.603354

    const [ensoleillementData, setEnsoleillementData] = useState(null);

    const [departementsGeoJSON, setDepartementsGeoJSON] = useState(null);
    const [departementId, setDepartementId] = useState(null);

    const [communesGeoJSON, setCommunesGeoJSON] = useState(null);
    const [communeId, setCommuneId] = useState(null)

    const [sectionGeoJSON, setSectionGeoJSON] = useState(null);
    const [sectionId, setSectionId] = useState(null);

    const [parcelleGeoJSON, setParcelleGeoJSON] = useState(null);

    const [parcelleId, setParcelleId] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(searchParams.get('zoom') ? parseInt(searchParams.get('zoom')) :  6);

    const [bounds, setBounds] = useState(null);
    const [loading, setLoading] = useState(true);
    const [communesLoaded, setCommunesLoaded] = useState(false);
    const [communesLoading, setCommunesLoading] = useState(false)
    const [sectionsLoaded, setSectionsLoaded] = useState(false);
    const [sectionsLoading, setSectionsLoading] = useState(false)
    const [parcellesLoading, setParcellesLoading] = useState(false);

    const [parcellesLoaded, setParcellesLoaded] = useState(false);

    const [enedisGeoJSON, setEnedisGeoJSON] = useState(null);
    const [hoveredLayer, setHoveredLayer] = useState(null)
    const [tooltipContent, setTooltipContent] = useState(null);

    const setStateFunction = (obj) => {
        const originalProperties = obj.originalProperties;
        // Déstructurez les propriétés de l'objet
        const {
            level,
            dep,
            depName,
            com,
            comName,
            section,
            sectionName,
            parcelle,
            parcelleName
        } = obj;

        if (level === "departement") {
            // Mise à jour de l'état pour le niveau "departement"
            setDepartementId(dep);
            setTooltipContent(<><h5>DÉPARTEMENT</h5>{dep} - {depName}<br/><br/>Ensoleillement moyen :<br/><b>{originalProperties?.sunlight}</b> heures/an</>);
        } else if (level === "commune") {
            // Mise à jour de l'état pour le niveau "commune"
            // setSectionsLoading(true);
            setCommuneId(com);
            setTooltipContent(<><h5>COMMUNE</h5>{com} - {comName}<br/><br/>Prix médian :<br/><b>{originalProperties?.median_price?.toFixed(2)}</b> €/m²</>);
        } else if (level === "section") {
            // setParcellesLoading(true);
            setSectionId(section);
            // setTooltipContent(`${com} - ${comName}\n - ${sectionName} - ${section} - ${sectionName}`);
            setTooltipContent(<>{jsonizeTooltipContent(originalProperties)}</>);
        } else if (level === "parcelle") {
            setParcelleId(parcelle);
            // setTooltipContent(`${com} - ${comName}\n - ${sectionName}\n - ${parcelleName}`);
            setTooltipContent(<>
                <h5>PARCELLE</h5>
                {originalProperties?.id} - {originalProperties?.valid !== false ? <b>VALIDE</b> : <b>INVALIDE</b>}
                <br/>
                <br/>
                Superficie :<br/><b>{(originalProperties?.surface / 10000).toFixed(2)}</b> ha
                {/*<br/>{jsonizeTooltipContent(originalProperties)}*/}
            </>);
        } else if (level === "enedis"){
            setTooltipContent(<>{originalProperties.type} {jsonizeTooltipContent(originalProperties.geo_point_2d)}</>)
        }
    };


    //
    // Fonctions exécutées aux changements de variables
    //

    useEffect(() => {
        centerLong = searchParams.get('center_long') ;
        centerLat = searchParams.get('center_lat');
        if (searchParams.get('zoom')) setZoomLevel(parseInt(searchParams.get('zoom')));
        fetchDepartementsGeoJSON().then(response => {
            setDepartementsGeoJSON(response);
            setLoading(false);

            // Extraction des données d'ensoleillement de l'objet departementsGeoJSO
            const ensoleillementData = response.features.map(feature => ({
                id: feature.properties.id,
                departement: feature.properties.nom,
                temps: feature.properties.sunlight
            }));

            // Mise à jour des valeurs minimales et maximales d'ensoleillement dans le parent MapView
            setters.setMinSun(Math.min(...ensoleillementData.map(data => data.temps)));
            setters.setMaxSun(Math.max(...ensoleillementData.map(data => data.temps)));

            setEnsoleillementData(ensoleillementData);
        });
    }, []);


    useEffect(() => {
        if (zoomLevel && bounds) fetchLayerFromZoomLevel()
    }, [zoomLevel, bounds])




    //
    // Événements de survol et de clic sur la carte
    //

    const calculateLayerData = (properties) => {
    const obj = {
        level: null,
        dep: null,
        depName: null,
        com: null,
        comName: null,
        section: null,
        sectionName: null,
        parcelle: null,
        parcelleName: null
    };

    const originalProperties = properties;

    const { code, insee_code, nom, name, county_code } = properties;
        let level;
        properties.type === "departement" ? level = "departement"
            : properties.type === "commune" ? level = "commune"
            : properties.type === "section" ? level = "section"
            : properties.type === "parcelle" ? level = "parcelle"
            : properties.type === "reseau-souterrain-hta" ? level = "enedis"
            : level = "fra";
        // console.log(level, insee_code, name);

        if (level === "fra") {
            obj.level = "fra";
        } else if (level === "departement" && code) {
            obj.level = "departement";
            obj.dep = code;
            obj.depName = nom;
        } else if (level === "commune" && insee_code) {
            obj.level = "commune";
            obj.dep = county_code
            obj.com = insee_code;
            obj.comName = name;
        } else if (level === "section") {
            obj.level = "section";
            // obj.com = code.substring(0, 5);
            // obj.dep = code.substring(0, 2);
            // obj.section = code;
            // obj.sectionName = code.slice(5).replace(/^0+/, ''); // Removing leading zeros
        } else if (level === "parcelle") {
            obj.level = "parcelle";
            // obj.com = code.substring(0, 5);
            // obj.section = code.substring(0, 10);
            // obj.sectionName = code.substring(5, 10).replace(/^0+/, ''); // Removing leading zeros
            // obj.parcelle = code;
            // obj.parcelleName = nom.slice(10).replace(/^0+/, ''); // Removing leading zeros
        }
        else if (level === "enedis") {
            obj.level = "enedis";
        }

        obj.properties = properties;
        obj.originalProperties = originalProperties;

        // Modifier l'état des composants
        setStateFunction(obj);
    };


    const onHoverClickLayer = (region, layer) => {
        layer.on({
            mouseover: () => {
                if (hoveredLayer !== layer._leaflet_id) {
                    console.log("changed layer", layer) //TODO: creuser pourquoi le leaflet_id monte si haut à force de déplacer la souris
                    setHoveredLayer(layer._leaflet_id)
                    calculateLayerData(region.properties);
                }
            },
            click: () => {
                console.log('Clic sur la région de carte', region, layer._leaflet_id);
                // calculateLayerData(region.properties);
                setters.setSidebar(region.properties)
            }
        });
    };


    //
    // Gestion du zoom
    //

    const clearMapLayers = () => {
        setDepartementsGeoJSON(null);
        setCommunesGeoJSON(null);
        setSectionGeoJSON(null);
        setParcelleGeoJSON(null);
        // setEnedisGeoJSON(null);
    };

    const fetchLayerFromZoomLevel = () => {
        console.log('Zoom level:', zoomLevel);
        console.log('Bounds:', bounds);
        const { lat: ne_lat, lng: ne_lng } = bounds._northEast;
        const { lat: sw_lat, lng: sw_lng } = bounds._southWest;


        if (zoomLevel > 13){
            console.log('Fetching parcelles...');
            setParcellesLoading(true);
            fetchParcellesGeoJSON(ne_lat, ne_lng, sw_lat, sw_lng).then(response => {
                if (!response || !response.features) return;
                clearMapLayers();
                if (response.features.has_plui) {
                    setParcelleGeoJSON(response.features.filter(feature => feature.properties.valid !== false ? feature : null));
                }
                else {
                    setParcelleGeoJSON(response.features);
                }
                setParcellesLoading(false);
                setParcellesLoaded(true);

                setters.setIsSunChecked(false);
                setters.setIsPriceChecked(false);
                setters.setIsPLUChecked(true);
            });
            fetchEnedisHTA(ne_lat, ne_lng, sw_lat, sw_lng).then(response => {
                if (!response || !response.features) return;
                setEnedisGeoJSON(response);
                setters.setIsEnedisChecked(true);
            });
        }
        // else if (zoomLevel >= 12){
        //     setSectionsLoading(true)
        //     console.log('Fetching sections for commune:', communeId);
        //     setParcellesLoading(true);
        //     fetchSectionsGeoJSON(ne_lat, ne_lng, sw_lat, sw_lng).then(response => {
        //         clearMapLayers();
        //         setSectionGeoJSON(response);
        //         setSectionsLoaded(true);
        //         setSectionsLoading(false)
        //     });
        // }
        else if (zoomLevel >= 9){
            console.log('Fetching communes...');
            setCommunesLoading(true)
            setParcellesLoaded(false);
            setSectionsLoaded(false);
            fetchCommunesGeoJSON(ne_lat, ne_lng, sw_lat, sw_lng).then(response => {
                if (!response || !response.features) return;
                clearMapLayers();
                setEnedisGeoJSON(null);
                setCommunesGeoJSON(response);
                setCommunesLoading(false)
                setCommunesLoaded(true);

                // setters.setMinPrice(Math.ceil(Math.min(...response.features?.map(data => data.properties.median_price))));
                setters.setMaxPrice(Math.ceil(Math.max(...response.features?.map(data => data.properties.median_price))));
                setters.setIsSunChecked(false);
                setters.setIsPriceChecked(true);
                setters.setIsPLUChecked(false);
                setters.setIsEnedisChecked(false);
            });
        }
        else {
            setParcellesLoaded(false);
            setSectionsLoaded(false);
            setCommunesLoaded(false);
            clearMapLayers();
            setEnedisGeoJSON(null);
            setters.setIsSunChecked(true);
            setters.setIsPriceChecked(false);
            setters.setIsPLUChecked(false);
            setters.setIsEnedisChecked(false);
        }
    };

    function ZoomHandler() {
        const map = useMapEvents({
            zoomend: (e) => {
                setZoomLevel(e.target.getZoom());
                setBounds(e.target.getBounds());
            },
            moveend: (e) => {
                setZoomLevel(e.target.getZoom());
                setBounds(e.target.getBounds());
            }
        });
        return null;
    }




    //
    // Style et rendu de la carte
    //

    const getColor = (feature, filters) => {
        if (feature.properties.type === "departement") {
            return getDepartementColor(filters, ensoleillementData, feature.properties.nom);
        }
        else if (feature.properties.type === "commune") {
            return getCommuneColor(filters, feature.properties, filters.minPrice, filters.maxPrice);
        }
        else if (feature.properties.type === "parcelle") {
            return getParcelleColor(filters, feature.properties)
        }
    }

    const style = (feature) => {
        return {
          fillColor: getColor(feature, filters),
          weight: 1,
          opacity: 0.8,
          color: 'white',
          dashArray: '3',
          fillOpacity: 0.6
        };
    };

    const uncoloredStyle = {
        fillOpacity: 0,
        weight: 1,
        opacity: 0.6,
        color: 'white',
        dashArray: '3'
    }

    return (
        <MapContainer center={[centerLong ? centerLong : 46.603354, centerLat ? centerLat : 1.888334]}
                      zoom={zoomLevel ? zoomLevel : 6}>
            {!isSatelliteChecked ? <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            /> : <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                // Source: https://geossc.ma/ajouter-les-couches-de-google-sur-qgis-3-x/
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            /> }
            <ZoomHandler />
            <SearchControl />
            {!loading ?
                <GeoJSON data={departementsGeoJSON}
                                     style={style}
                                     onEachFeature={onHoverClickLayer}>
                    <Tooltip direction="auto" sticky="true" offset={[5, 0]} opacity={0.8}>
                        {tooltipContent}
                    </Tooltip>
                </GeoJSON>
            : <Loader></Loader>
            }
            {communesLoaded ?
                <GeoJSON
                  key={`communes-${JSON.stringify(bounds)}`}
                  data={communesGeoJSON} // Fonction pour récupérer le GeoJSON correspondant au code de la commune
                  style={style}
                  onEachFeature={onHoverClickLayer}
                >
                  <Tooltip direction="auto" sticky="true" offset={[5, 0]} opacity={0.8}>
                    {tooltipContent}
                  </Tooltip>
                </GeoJSON>
                : <></>}
            {communesLoading ? <Loader light={true}></Loader> : <></>}
            {/*{sectionsLoaded ?*/}
            {/*    <GeoJSON*/}
            {/*      key={`sections-${JSON.stringify(bounds)}-${new Date().getTime()}`}*/}
            {/*      data={sectionGeoJSON} // Fonction pour récupérer le GeoJSON correspondant au code de la section*/}
            {/*      style={uncoloredStyle}*/}
            {/*      onEachFeature={onHoverClickLayer}*/}
            {/*    >*/}
            {/*      /!*<Tooltip direction="auto" sticky="true" offset={[5, 0]} opacity={0.8}>*!/*/}
            {/*      /!*  {tooltipContent}*!/*/}
            {/*      /!*</Tooltip>*!/*/}
            {/*    </GeoJSON>*/}
            {/*: <></>}*/}
            {sectionsLoading ? <Loader light={true}></Loader> : <></>}
            {parcellesLoaded ?
                <GeoJSON
                  // key={`parcelles-${JSON.stringify(bounds)}`}
                  data={parcelleGeoJSON} // Fonction pour récupérer le GeoJSON correspondant au code de la parcelle
                  style={style}
                  onEachFeature={onHoverClickLayer}
                >
                  <Tooltip direction="auto" sticky="true" offset={[5, 0]} opacity={0.8}>
                    {tooltipContent}
                  </Tooltip>
                </GeoJSON>
            : <></>}
            {parcellesLoading ? <Loader light={true}></Loader> : <></>}
            { filters.enedisVisibility && enedisGeoJSON ?
                <GeoJSON
                    key={`enedis-${bounds}-${new Date().getTime()}`}
                    data={enedisGeoJSON}
                    style={{
                        weight: 10,
                        opacity: 0.6,
                        color: 'red',
                    }}
                    onEachFeature={onHoverClickLayer}
                >
                {/*<Tooltip direction="auto" sticky="true" offset={[5, 0]} opacity={0.8}>*/}
                {/*    {tooltipContent}*/}
                {/*</Tooltip>*/}
                </GeoJSON> : <></>

            }
        </MapContainer>
    );
}

export default MapComponent;
