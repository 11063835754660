import {useMap} from "react-leaflet";
import {useEffect} from "react";
import {useLocation} from "react-router";

import {GeoSearchControl, OpenStreetMapProvider} from "leaflet-geosearch";
import 'leaflet-geosearch/dist/geosearch.css';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const SearchControl = () => {
        const map = useMap();
        const query = useQuery();
        const searchAddress = query.get('search');

        useEffect(() => {
            const provider = new OpenStreetMapProvider();
            const searchControl = new GeoSearchControl({
                provider,
                style: 'button',
                showMarker: false,
                showPopup: false,
                // marker: {
                //     // icon: new L.Icon.Default(),
                //     draggable: false,
                // },
                resultFormat: ({ result }) => result.label,
                maxMarkers: 1,
                retainZoomLevel: false,
                animateZoom: true,
                autoClose: true,
                searchLabel: 'Enter address',
                keepResult: true
            });

            map.addControl(searchControl);

            const executeSearch = async (address) => {
                const results = await provider.search({ query: address });
                if (results && results.length > 0) {
                    const result = results[0];
                    const { x, y } = result;
                    map.setView([y, x], 15, {
                        animate: true,
                        pan: {
                            duration: 1
                        },
                        zoom: {
                            animate: true
                        }
                    });
                    // Ajouter un marqueur à la position trouvée
                    // L.marker([y, x], { icon: new L.Icon.Default() }).addTo(map);
                }
            };

            // Exécuter la recherche si une adresse est présente dans l'URL
            if (searchAddress) {
                executeSearch(searchAddress);
            }

            return () => map.removeControl(searchControl);
        }, [map, searchAddress]);


        return null;
    };

export default SearchControl;